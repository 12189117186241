import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CardIssuedInfo extends Vue {
  @Prop({ type: String }) private readonly title!: string;

  private onClose() {
    this.$emit("close");
  }
}
