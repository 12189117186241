var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.$vuetify.breakpoint.mobile)?_c('BaseImg',{staticClass:"mb-10 mx-auto",attrs:{"src":require('./img/logo.svg'),"max-width":"110"}}):_vm._e(),(_vm.$vuetify.breakpoint.mobile && _vm.title)?_c('h3',{staticClass:"text-h5 font-weight-bold black--text mb-6"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('Trans',{class:[
      'black--text',
      {
        'text-subtitle-2 text-left font-weight-regular':
          _vm.$vuetify.breakpoint.mobile,
        'text-h6 text-center': !_vm.$vuetify.breakpoint.mobile,
      },
    ],attrs:{"tag":"div","trans-key":"$vuetify.dashboard.card.batch_issue.comming_soon"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{style:({
          verticalAlign: 'middle',
          fontSize: '1.5em',
        }),attrs:{"color":"primary"}},[_vm._v(" $creditCard ")])]},proxy:true}])}),_c('v-btn',{staticClass:"mt-6",attrs:{"height":_vm.$vuetify.breakpoint.mobile ? 36 : 44,"ripple":false,"block":"","color":"primary"},on:{"click":_vm.onClose}},[_c('span',{class:{ 'text-subtitle-3': !_vm.$vuetify.breakpoint.mobile }},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.continue"))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }